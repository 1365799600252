import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import SendMessage from './SendMessage';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

// Configuração do tema Material UI
const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    {/* Rota de login */}
                    <Route path="/" element={<Login />} />

                    {/* Rota de envio de mensagens */}
                    <Route path="/send-message" element={<SendMessage />} />

                    {/* Rota para redirecionamento em caso de URLs desconhecidas */}
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
